
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeAddModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_work_type_add_modal_info !: organization_modal_interface.OrganizationWorkTypeAddModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeAddModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => void;

    work_type_name : string = "";

    contents : any[] = [
        { week:0, am_from: ''    , am_to: ''    , pm_from: ''    , pm_to: ''    , is_beacon_use : false },
        { week:1, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:2, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:3, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:4, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:5, am_from: '0900', am_to: '1200', pm_from: '1300', pm_to: '1800', is_beacon_use : false },
        { week:6, am_from: ''    , am_to: ''    , pm_from: ''    , pm_to: ''    , is_beacon_use : false },
    ];

    is_beacon_auto : boolean = false;
    is_visible_beacon_auto : boolean = false;

    mounted() : void {
        if( this.organization_work_type_add_modal_info.is_create == false ) {
            this.getOrganizationWorktype();
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype?work_type_id=${this.organization_work_type_add_modal_info.work_type_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            const work_type_info = response.data.data.work_type_info;
            
            this.work_type_name = work_type_info.work_type_name;
            this.is_beacon_auto = work_type_info.is_beacon_auto;
            
            let count = 0;
            for( const content of work_type_info.contents ) {

                if( content.is_beacon_use == true ) {
                    count++;
                }

                if( content.is_telecommuting == true ) {
                    content.am_from = 'home';
                    content.am_to   = 'home';
                    content.pm_from = 'home';
                    content.pm_to   = 'home';
                    continue;
                }

                if( content.am_from == null ) content.am_from = ''; 
                if( content.am_to   == null ) content.am_to   = ''; 
                if( content.pm_from == null ) content.pm_from = ''; 
                if( content.pm_to   == null ) content.pm_to   = ''; 
            }

            this.is_visible_beacon_auto = count > 0;

            this.contents = JSON.parse(JSON.stringify(work_type_info.contents));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근무 타입 조회 중 오류 발생");
            this.close();
        }
    }

    /**
     * am_from 선택
     */
    changeAmFrom(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.am_to = '';

            if( day.pm_from == 'home' ) {
                day.pm_from = '';
                day.pm_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_to = 'home';
            day.pm_from = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.pm_from == 'home' ) {
            day.pm_from = '';
            day.pm_to   = '';
        }

        if( day.am_to == '' || day.am_to == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_to = `0${Number(value) + 30}`.slice(-4);
            }
            return;
        }

        const number_am_from = Number(day.am_from);
        const number_am_to   = Number(day.am_to);

        if( number_am_from >= number_am_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_to = `0${Number(value) + 30}`.slice(-4);
            }
        }

        
    }
    
    /**
     * am_to 선택
     */
    changeAmTo(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.am_from = '';

            if( day.pm_from == 'home' ) {
                day.pm_from = '';
                day.pm_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.pm_from = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.pm_from == 'home' ) {
            day.pm_from = '';
            day.pm_to   = '';
        }

        if( day.am_from == '' || day.am_from == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_from = `0${Number(value) - 70}`.slice(-4);
            }
            return;
        }

        const number_am_from = Number(day.am_from);
        const number_am_to   = Number(day.am_to);

        if( number_am_from >= number_am_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.am_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.am_from = `0${Number(value) - 70}`.slice(-4);
            }
        }

    }

    /**
     * pm_from 선택
     */
    changePmFrom(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.pm_to = '';

            if( day.am_from == 'home' ) {
                day.am_from = '';
                day.am_to   = '';
            }

            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.am_to   = 'home';
            day.pm_to   = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.am_from == 'home' ) {
            day.am_from = '';
            day.am_to   = '';
        }

        if( day.pm_to == '' || day.pm_to == 'home' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_to = `0${Number(value) + 30}`.slice(-4);
            }
            return;
        }

        const number_pm_from = Number(day.pm_from);
        const number_pm_to   = Number(day.pm_to);

        if( number_pm_from >= number_pm_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_to = `0${Number(value) + 70}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_to = `0${Number(value) + 30}`.slice(-4);
            }
        }
    }

    /**
     * pm_to 선택
     */
    changePmTo(event, week) : void {
        const value = event.target.value;
        const day   = this.contents[week];

        if( value == '' ) {
            day.pm_from = '';
            
            if( day.am_from == 'home' ) {
                day.am_from = '';
                day.am_to   = '';
            }
            
            return;
        }

        else if( value == 'home' ) {
            day.am_from = 'home';
            day.am_to   = 'home';
            day.pm_from = 'home';
            day.is_beacon_use = false;
            return;
        }

        if( day.am_from == 'home' ) {
            day.am_from = '';
            day.am_to   = '';
        }

        if( day.pm_from == '' || day.pm_to == '' ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_from = `0${Number(value) - 70}`.slice(-4);
            }
            return;
        }

        const number_pm_from = Number(day.pm_from);
        const number_pm_to   = Number(day.pm_to);

        if( number_pm_from >= number_pm_to ) {
            if( new RegExp(/[0-9]{2,2}30/).test(value) ) {
                day.pm_from = `0${Number(value) - 30}`.slice(-4);
            }
            else if( new RegExp(/[0-9]{2,2}00/).test(value) ) {
                day.pm_from = `0${Number(value) - 70}`.slice(-4);
            }
        }
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 비콘 사용여부 체크 (전부 사용, 전부 사용 안함 일때만 기능)
     */
    changeBeaconUse(event) : void {

        const value = event.target.value;

        if( value == "all_true" ) {
            for( const day of this.contents ) {
                day.is_beacon_use = "true";
            }
        }

        else if( value == "all_false" ) {
            for( const day of this.contents ) {
                day.is_beacon_use = "false";
            }
        }

        let count = 0;
        for( const day of this.contents ) {
            if( day.is_beacon_use == "true" ) count++;
        }

        this.is_visible_beacon_auto = count > 0;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationWorkTypeAddModalInfo?.({ show_modal : false, is_create : true, work_type_id : 0 });
    }

    /**
     * 모달 저장
     */
    async save() : Promise<void> {

        if( this.work_type_name.trim().length < 1 ) {
            alert("근무 타입 이름을 작성해주세요");
            $('#organization_work_type_add_modal .titleBox > h1 > input[type="text"]').focus();
            return;
        }

        for( const content of this.contents ) {
            const index = this.contents.indexOf(content);

            if( content.am_from && content.pm_from ) {
                const number_am_to   = Number(content.am_to);
                const number_pm_from = Number(content.pm_from);

                if( number_am_to > number_pm_from ) {
                    alert(`${this.getDayName(content.week, '요일')} 오전 근무시간과 오후 근무시간이 겹칩니다`);
                    return;
                }
            }
        }

        try {
            const contents = JSON.parse(JSON.stringify(this.contents));

            for( const content of contents ) {

                content.is_beacon_use = content.is_beacon_use == true || content.is_beacon_use == 'true'; 
                
                if( content.am_from == 'home' || content.am_to == 'home' || content.pm_from == 'home' || content.pm_to == 'home' ) {
                    content.am_from = null;
                    content.am_to   = null;
                    content.pm_from = null;
                    content.pm_to   = null;
                    content.is_telecommuting = true;
                    continue;
                }
                
                content.is_telecommuting = false;
                if( content.am_from == '' ) content.am_from = null;
                if( content.am_to   == '' ) content.am_to   = null;
                if( content.pm_from == '' ) content.pm_from = null;
                if( content.pm_to   == '' ) content.pm_to   = null;
            }

            const data = {
                group_id : this.scope_group_id,
                work_type_name : this.work_type_name.trim(),
                contents :  contents,
                is_beacon_auto : this.is_beacon_auto
            }

            if( this.organization_work_type_add_modal_info.is_create == true ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.POST, data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("조직도 근무 타입 생성 중 오류 발생");
                }

                this.organization_work_type_add_modal_info.callback?.();
                this.close();
            }
            
            else {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype/${this.organization_work_type_add_modal_info.work_type_id}`, API_METHOD.PUT, data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("조직도 근무 타입 수정 중 오류 발생");
                }

                this.organization_work_type_add_modal_info.callback?.();
                this.close();
            }

        } catch(e) {
            if( this.organization_work_type_add_modal_info.is_create == true ) {
                alert("조직도 근무 타입 생성 중 오류 발생");
            }
            else {
                alert("조직도 근무 타입 수정 중 오류 발생");
            }
            this.hodu_error_process(e, false, false, true);
        }
        
    }

}
